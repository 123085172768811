.page-header {
  .page-branding {
    padding-top: 14px;
  }
  .page-branding__trustmark {
    margin-left: 7px;
  }
}

.trust-mark-elc-nodeblock {
  float: left;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -14px;
      background-image: url('/media/images/global/lo3.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
    }
  }
}

.page-navigation__menu {
  .menu-ref:first-child {
    @media #{$xlarge-up} {
      padding-#{$ldirection}: 106px;
    }
  }
}
